import React from 'react';
// import { Helmet } from 'react-helmet';


import ami from '../assets/contributors/ami.jpeg';
import timothe from '../assets/contributors/timothe.jpeg';



const About: React.FC = () => {
    return (
        <div className="limitmaxwidth">
            {/* <Helmet>
                <title>About Morfelix.ai</title>
                <meta name="description" content="Learn who created Morfelix.ai" />
            </Helmet> */}
            <article>
                <div>
                    <section className="py-4">
                        <div className="row justify-content-center">
                            <h2>
                                Contributors</h2>
                        </div>
                        <div className="row py-4">
                            <div className="col-6 justify-content-center text-center">
                                <div className="container px-5 py-2">
                                    <img className="rounded-circle img-thumbnail contributor-image" src={ami} alt="ami" />
                                </div>
                                <h6 className="card-title">
                                    Ami Beuret</h6>
                                <span className="card-subtitle text-muted">
                                    ETH Zürich</span>
                                <p className="contributor-text py-3">
                                    PhD candidate at Information Science and Engineering group at ETH Zurich working on intersection of machine learning and medicine.
                                </p>
                            </div>
                            <div className="col-6 justify-content-center text-center">
                                <div className="container px-5 py-2">
                                    <img className="rounded-circle img-thumbnail contributor-image" src={timothe} alt="timothe" />
                                </div>
                                <h6 className="card-title">
                                    Timothé Laborie</h6>
                                <span className="card-subtitle text-muted">
                                    ETH Zürich</span>
                                <p className="contributor-text py-3">
                                    Computer Science master student at ETH Zurich with a strong interest in machine learning and its applications.
                                </p>
                            </div>
                        </div>
                        <div className="row py-4">
                        </div>
                    </section>
                </div>
            </article>
        </div>

    );
};

export default About;